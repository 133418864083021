@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .background {
    background: url('./background.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }
}

@media print {
  .no-print {
    display: none;
  }
}
