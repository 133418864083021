/* background is a image that covers the entire viewport, should not move, and should not shrink when the screen shrinks */

body::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url(../public/zen-texture-base.png) url(../public/zen-texture-1.png) url(../public/zen-texture-2.png)
    url(../public/zen-texture-3.png) url(../public/zen-texture-4.png) url(../public/zen-texture-5.png) url(../public/zen-texture-6.png)
    url(../public/zen-texture-7.png) url(../public/zen-texture-8.png) url(../public/zen-texture-9.png);
}
