.bg1 {
  background-color: #97a6bc;
}

.bg2 {
  background-color: #b1bfba;
}

.bg3 {
  background-color: #c5aa9f;
}

.bg4 {
  background-color: #a3a383;
}

.btn1 {
  background-color: #b7c9e5;
}

.btn2 {
  background-color: #cee0da;
}

.btn3 {
  background-color: #e4c8bd;
}

.btn4 {
  background-color: #d0d0b2;
}
