/* Slider.css */
.slider-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100%;
  overflow: hidden;
}

.slider {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
}

.slides {
  display: flex;
  width: 100%;
  height: 100%;
  transition: transform 0.7s ease-in-out;
}

.slide {
  min-width: 100%;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  font-size: 24px;
}
