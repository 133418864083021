.bg-zen1 {
  background-image: url('../../../public/zen-texture-1.png');
  background-size: cover;
}

.bg-zen2 {
  background-image: url('../../../public/zen-texture-2.png');
  background-size: cover;
}

.bg-zen3 {
  background-image: url('../../../public/zen-texture-3.png');
  background-size: cover;
}

.bg-zen4 {
  background-image: url('../../../public/zen-texture-4.png');
  background-size: cover;
}

.bg-zen5 {
  background-image: url('../../../public/zen-texture-5.png');
  background-size: cover;
}

.bg-zen6 {
  background-image: url('../../../public/zen-texture-6.png');
  background-size: cover;
}

.bg-zen7 {
  background-image: url('../../../public/zen-texture-7.png');
  background-size: cover;
}

.bg-zen8 {
  background-image: url('../../../public/zen-texture-8.png');
  background-size: cover;
}

.bg-zen9 {
  background-image: url('../../../public/zen-texture-9.png');
  background-size: cover;
}

.bg-zen {
  background-image: url('../../../public/zen-texture-base.png');
  background-size: cover;
}
